import {
  ChartBarIcon,
  DocumentCheckIcon,
  DocumentIcon,
} from '@heroicons/react/24/outline';
import {
  Banknote,
  BoxIcon,
  Building2,
  CircleSlash,
  Send,
  Ship,
  ListOrderedIcon,
  ShipIcon,
  Box,
  Boxes,
  Truck,
  Book,
  Calculator,
  Users,
  Mail,
  PlaneTakeoff,
  CopySlash,
} from 'lucide-react';

export interface IRoute {
  text: string;
  href: string;
  as?: string;
  privileges?: string[];
  key?: string;
  isCollapsible?: boolean;
  icon?: JSX.Element;
  children?: IRoute[];
  breadcrumb?: IBreadcrumb[];
}
export interface IBreadcrumb {
  name: string;
  href: string;
}

export const routes = [
  // {
  //   text: 'Dashboard',
  //   href: '/app/dashboard',
  //   as: '/app/dashboard',
  //   isCollapsible: false,
  //   icon: <ChartBarIcon className=" w-6 h-6 " />,
  //   key: 'dashboard',
  //   permission: { key: 'dashboard', option: 'read' },
  // },
  // {
  //   text: 'Envíos',
  //   href: '/app/shipments',
  //   as: '/app/shipments',
  //   isCollapsible: false,
  //   icon: <Send size={24} className="" />,
  //   key: 'orders',
  //   permission: { key: 'orders', option: 'read' },
  // },
  // {
  //   text: 'Container',
  //   href: '/app/containers',
  //   as: '/app/containers',
  //   isCollapsible: false,
  //   icon: <BoxIcon size={24} className="" />,
  //   key: 'container',
  //   permission: { key: 'containers', option: 'read' },
  // },
  {
    text: 'Envíos',
    href: '/app/orders',
    as: '/app/orders',
    isCollapsible: false,
    icon: <DocumentCheckIcon className=" w-6 h-6 " />,
    key: 'orders',
    permission: { key: 'orders', option: 'read' },
  },
  // {
  //   text: 'Clientes',
  //   href: '/app/clients',
  //   as: '/app/clients',
  //   isCollapsible: false,
  //   icon: getIcon('clients', 'fill-primary-100 mr-2'),
  //   key: 'clients',
  //   permission: { key: 'client', option: 'read' },
  // },
  // {
  //   text: 'Tarifas',
  //   href: '/app/prices',
  //   as: '/app/prices',
  //   isCollapsible: false,
  //   icon: getIcon('firm', 'fill-primary-100 mr-2'),
  //   key: 'prices',
  //   permission: { key: 'price', option: 'read' },
  // },

  // {
  //   text: 'Promociones',
  //   href: '/app/promotions',
  //   as: '/app/promotions',
  //   isCollapsible: false,
  //   icon: getIcon('promotions', 'fill-primary-100 mr-2'),
  //   key: 'promotions',
  //   permission: { key: 'promotion', option: 'read' },
  // },
  // {
  //   text: 'Banners',
  //   href: '/app/banners',
  //   as: '/app/banners',
  //   isCollapsible: false,
  //   icon: <CircleSlash size={24} className="" />,
  //   key: 'banners',
  //   permission: { key: 'banner', option: 'read' },
  // },
  {
    text: 'Fletes',
    href: '/app/freights',
    as: '/app/freights',
    isCollapsible: false,
    icon: <Boxes size={24} className="" />,
    key: 'freights',
    permission: { key: 'freights', option: 'read' },
  },
  {
    text: 'Pagos',
    href: '/app/payments',
    as: '/app/payments',
    isCollapsible: false,
    icon: <Banknote size={24} className="" />,
    key: 'payments',
    permission: { key: 'payments', option: 'read' },
  },
  {
    text: 'Calculadora',
    href: '/app/calculator',
    as: '/app/calculator',
    isCollapsible: false,
    icon: <Calculator size={24} className="" />,
    key: 'calculator',
    permission: { key: 'calculator', option: 'read' },
  },
  {
    text: 'Contenedores',
    href: '/app/loads',
    as: '/app/loads',
    isCollapsible: false,

    icon: <Truck size={24} className="" />,
    key: 'loads',
    permission: { key: 'loads', option: 'read' },
  },
  {
    text: 'Aviones',
    href: '/app/aircrafts',
    as: '/app/aircrafts',
    isCollapsible: false,

    icon: <PlaneTakeoff size={24} className="" />,
    key: 'aircrafts',
    permission: { key: 'aircrafts', option: 'read' },
  },
  {
    text: 'Métodos de Pago',
    href: '/app/payment-methods',
    as: '/app/payment-methods',
    isCollapsible: false,

    icon: <Banknote size={24} className="" />,
    key: 'payment-methods',
    permission: { key: 'payment_methods', option: 'read' },
  },
  {
    text: 'Cotizaciones',
    href: '/app/quotations',
    as: '/app/quotations',
    isCollapsible: false,

    icon: <CopySlash size={24} className="" />,
    key: 'quotations',
    permission: { key: 'quotations', option: 'read' },
  },
  {
    text: 'Tasa',
    href: '/app/rate',
    as: '/app/rate',
    isCollapsible: false,

    icon: <Banknote size={24} className="" />,
    key: 'rate',
    permission: { key: 'rate', option: 'read' },
  },
  // {
  //   text: 'Tutoriales',
  //   href: '/app/tutorials',
  //   as: '/app/tutorials',
  //   isCollapsible: false,

  //   icon: <Book size={24} className="" />,
  //   key: 'rate',
  //   permission: { key: 'tutorials', option: 'read' },
  // },
  // {
  //   text: 'Aliados',
  //   href: '/app/shipping-companies',
  //   as: '/app/shipping-companies',
  //   isCollapsible: false,

  //   icon: <Building2 size={24} className="" />,
  //   key: 'shipping-companies',
  //   permission: { key: 'shipping-companies', option: 'read' },
  // },
  // {
  //   text: 'Buques',
  //   href: '/app/vessels',
  //   as: '/app/vessels',
  //   isCollapsible: false,

  //   icon: <Ship size={24} className="" />,
  //   key: 'vessel',
  //   permission: { key: 'vessel', option: 'read' },
  // },
  {
    text: 'Clientes y Usuarios',
    href: '/app/users',
    as: '/app/users',
    isCollapsible: false,
    icon: <Users size={24} className="" />,
    key: 'users',
    permission: { key: 'users', option: 'read' },
  },
  // sugerencias
  {
    text: 'Sugerencias',
    href: '/app/suggestions',
    as: '/app/suggestions',
    isCollapsible: false,
    icon: <ListOrderedIcon size={24} className="" />,
    key: 'suggestions',
    permission: { key: 'suggestions', option: 'read' },
  },
  {
    text: 'Anuncios',
    href: '/app/announcement',
    as: '/app/announcement',
    isCollapsible: false,
    icon: <Mail size={24} className="" />,
    key: 'announcement',
    permission: { key: 'announcement', option: 'read' },
  },

  // {
  //   text: 'Mantenimiento',
  //   isCollapsible: true,
  //   icon: (
  //     <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
  //       <path
  //         fillRule="evenodd"
  //         d="M4 4a2 2 0 00-2 2v4a2 2 0 002 2V6h10a2 2 0 00-2-2H4zm2 6a2 2 0 012-2h8a2 2 0 012 2v4a2 2 0 01-2 2H8a2 2 0 01-2-2v-4zm6 4a2 2 0 100-4 2 2 0 000 4z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  //   permission: { key: 'client', option: 'read' },
  //   subLinks: [
  //     {
  //       text: 'Promociones',
  //       href: '/app/promotions',
  //       as: '/app/promotions',
  //       key: 'promotions',
  //       icon: getIcon('promotions', 'fill-primary-100 mr-2 h-3 w-3'),
  //     },
  //   ],
  // },
  // {
  //   text: 'Configuración',
  //   isCollapsible: true,
  //   icon: (
  //     <svg fill="currentColor" className="w-5 h-5 mr-2" viewBox="0 0 20 20">
  //       <path d="M4 3a2 2 0 100 4h12a2 2 0 100-4H4z" />
  //       <path
  //         fillRule="evenodd"
  //         d="M3 8h14v7a2 2 0 01-2 2H5a2 2 0 01-2-2V8zm5 3a1 1 0 011-1h2a1 1 0 110 2H9a1 1 0 01-1-1z"
  //         clipRule="evenodd"
  //       />
  //     </svg>
  //   ),
  //   permission: { key: 'client', option: 'read' },
  //   subLinks: [
  //     {
  //       text: 'Países',
  //       href: '/app/countries',
  //       as: '/app/countries',
  //       key: 'countries',
  //       icon: getIcon('countries', 'fill-primary-100 mr-2 h-4 w-4'),
  //       permission: { key: 'country', option: 'read' },
  //     },
  //     {
  //       text: 'Monedas',
  //       href: '/app/currencies',
  //       as: '/app/currencies',
  //       key: 'currencies',
  //       icon: getIcon('currency', 'fill-primary-100 mr-2 h-4 w-4'),
  //       permission: { key: 'currency', option: 'read' },
  //     },
  //     {
  //       text: 'Métodos de Pago',
  //       href: '/app/payment-methods',
  //       as: '/app/payment-methods',
  //       key: 'payment-methods',
  //       icon: getIcon('paymentMethod', 'fill-primary-100 mr-2 h-4 w-4'),
  //       permission: { key: 'paymentMethod', option: 'read' },
  //     },
  //     // {
  //     //   text: 'Impuestos',
  //     //   href: '/app/taxes',
  //     //   as: '/app/taxes',
  //     //   key: 'taxes',
  //     //   icon: getIcon('taxes', 'fill-primary-100 mr-2 h-4 w-4'),
  //     //   permission: { key: 'tax', option: 'read' },
  //     // },
  //     {
  //       text: 'Variables',
  //       href: '/app/variables',
  //       as: '/app/variables',
  //       key: 'variables',
  //       icon: getIcon('variables', 'fill-primary-100 mr-2 h-4 w-4'),
  //       permission: { key: 'variable', option: 'read' },
  //     },
  //   ],
  // },
];

export default routes;
